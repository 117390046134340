import '../styles/globals.css';
import 'maplibre-gl/dist/maplibre-gl.css';

import { Figtree, Roboto_Mono } from '@next/font/google';
import SessionProvider from 'lib/components/misc/SessionProvider';
import { type AppType } from 'next/app';
import { PostHogProvider } from 'posthog-js/react';
import { api } from 'utils/client';
import { LoaderProvider } from 'utils/images/LoaderContext';

const figtree = Figtree({
  subsets: ['latin'],
});

const robotoMono = Roboto_Mono({
  subsets: ['latin'],
});

const posthogOptions = {
  api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.posthog.com',
};

const App: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <style jsx global>
        {`
          html,
          :root {
            --font-figtree: ${figtree.style.fontFamily};
            --font-roboto-mono: ${robotoMono.style.fontFamily};
          }
        `}
      </style>
      <PostHogProvider apiKey={process.env.NEXT_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
        <LoaderProvider>
          <SessionProvider>
            <Component {...pageProps} />
          </SessionProvider>
        </LoaderProvider>
      </PostHogProvider>
    </>
  );
};

export default api.withTRPC(App);
