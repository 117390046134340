import { createStore, useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { AuthCtx, authCtxStorageKey } from './authContext';
import { unauthenticatedAuthCtx } from './authContext';

interface AuthStoreState {
  authCtx: AuthCtx;
  setAuthCtx: (authCtx: AuthCtx) => void;
}

export const authStore = createStore(
  persist<AuthStoreState>(
    (set) => ({
      authCtx: unauthenticatedAuthCtx,
      setAuthCtx: (authCtx: AuthCtx) => set({ authCtx }),
    }),
    {
      name: authCtxStorageKey,
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useAuthStore = (selector: (state: AuthStoreState) => unknown) => useStore(authStore, selector);
