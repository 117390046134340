/**
 * This is the client-side entrypoint for your tRPC API.
 * It is used to create the `api` object which contains the Next.js
 * App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types
 */
import { createWSClient, httpBatchLink, loggerLink, splitLink, wsLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'server/api/root';
import superjson from 'superjson';

import { authStore } from './authStore';

const getBaseUrl = () => {
  if (typeof window !== 'undefined') return '';
  return process.env.NEXT_PUBLIC_APP_URL;
};

const httpLink = httpBatchLink({
  headers() {
    const { authCtx } = authStore.getState();

    if (!authCtx.authenticated || !authCtx.csrfToken) return {};

    return {
      'X-CSRF-Token': authCtx.csrfToken,
    };
  },
  url: `${getBaseUrl()}/api/trpc`,
  fetch(input, init) {
    const promise = fetch(input, init);
    promise
      .then((res) => {
        const refreshCsrfToken = res?.headers?.get('X-Refresh-CSRF-Token');
        if (refreshCsrfToken) {
          authStore.setState({
            authCtx: {
              authenticated: true,
              csrfToken: refreshCsrfToken,
            },
          });

          console.debug('updated authCtx in storage with refreshed CSRF token');
        }

        if (res.status === 401) {
          authStore.setState({
            authCtx: {
              authenticated: false,
              csrfToken: undefined,
            },
          });

          console.debug('updated authCtx in storage with unauthenticated state');
        }
      })
      .catch();
    return promise;
  },
});

function getEndingLink() {
  if (typeof window === 'undefined') {
    return httpLink;
  }

  return splitLink({
    condition(op) {
      return op.type === 'subscription';
    },
    true: wsLink({
      client: createWSClient({
        url: process.env.NEXT_PUBLIC_WS_URL!,
      }),
    }),
    false: httpLink,
  });
}

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       **/
      transformer: superjson,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       * */
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
        }),
        getEndingLink(),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;
