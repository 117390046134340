import type { Role } from '@prisma/client';

export const authCtxStorageKey = 'quizzlybear_auth_context';

export const unauthenticatedAuthCtx: AuthCtx = { authenticated: false };

export type AuthCtx = {
  id?: string;
  email?: string;
  role?: Role;
  csrfToken?: string;
  authenticated: boolean;
};

export function fetchAuthCtx(): AuthCtx | null {
  const jsonValue = localStorage.getItem(authCtxStorageKey);
  if (jsonValue !== null) {
    const authCtx = JSON.parse(jsonValue);
    return authCtx;
  }
  return null;
}

export function storeAuthCtx(authCtx: AuthCtx) {
  localStorage.setItem(authCtxStorageKey, JSON.stringify(authCtx));
}

export function removeAuthCtx() {
  localStorage.removeItem(authCtxStorageKey);
}

export function initialAuthCtx() {
  // Just assume we are not authenticated when server rendering
  if (typeof window === 'undefined') {
    return unauthenticatedAuthCtx;
  }
  // Otherwise fetch the auth context from local storage
  return fetchAuthCtx() || unauthenticatedAuthCtx;
}
