import { ImageLoader } from 'next/image';
import { createContext, useContext } from 'react';

import dummyImageLoaderFactory from './dummy-image-loader-factory';
import imgproxyLoaderFactory, { ImgproxyImageProps } from './imgproxy-loader-factory';

type LoaderContextProps = ({ src, width, quality }: ImgproxyImageProps) => string;

const LoaderContext = createContext<LoaderContextProps | null>(null);

const useLoader = (opts = {}) => {
  const loader = useContext(LoaderContext);
  if (loader) {
    // Pass opts to call to loader function
    return ((args: ImgproxyImageProps) => loader({ ...opts, ...args })) as ImageLoader;
  }
};

const LoaderProvider = ({ children }: { children: React.ReactNode }) => {
  const loader = createLoader();

  if (!loader) return <>{children}</>;

  return <LoaderContext.Provider value={loader}>{children}</LoaderContext.Provider>;
};

const DEBUG_RESPONSIVE_IMAGES = process.env.DEBUG_RESPONSIVE_IMAGES;

const createLoader = () => {
  if (DEBUG_RESPONSIVE_IMAGES === 'true') {
    return dummyImageLoaderFactory();
  }

  return imgproxyLoaderFactory();
};

export default LoaderContext;
export { LoaderProvider, useLoader };
