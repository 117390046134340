import { User } from '@prisma/client';
import { createContext, useState } from 'react';

export type SessionProps = {
  user: Pick<User, 'id' | 'email' | 'role'> | null;
};

type SessionContextProps = {
  session: SessionProps;
  setSession: (session: SessionProps) => void;
};

export const SessionContext = createContext<SessionContextProps>({ session: { user: null }, setSession: () => {} });

type SessionProviderProps = {
  children: React.ReactNode;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
  const [session, setSession] = useState<SessionProps>({ user: null });

  return <SessionContext.Provider value={{ session, setSession }}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
