import { urlSafeBase64 } from './urlSafeBase64';

export type ImgproxyImageProps = {
  src: string;
  width: number;
  height?: number;
  vAlign?: 'top' | 'center' | 'bottom';
  quality: number;
  ratio?: number;
  gravity?: string;
  enlarge?: boolean;
  absolute?: boolean;
};

const imgproxyLoaderFactory =
  () =>
  ({ src, width, height, vAlign, ratio, absolute = false }: ImgproxyImageProps) => {
    const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
    const encodedURL = urlSafeBase64(src);
    const params = new URLSearchParams();

    let path = '';

    params.append('width', width.toString());

    if (height) {
      params.append('height', height.toString());
    }

    if (ratio) {
      params.append('ratio', ratio.toString());
    }

    if (vAlign) {
      params.append('valign', vAlign);
    }

    if (absolute && APP_URL) {
      let baseUrl = APP_URL;

      if (baseUrl.slice(-1) === '/') {
        baseUrl = baseUrl.slice(0, -1);
      }

      path = baseUrl;
    }

    path += `/api/image/${encodedURL}?${params.toString()}`;

    return path;
  };

export default imgproxyLoaderFactory;
